import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import styles from './TokenReport.module.css';

const TokenReport = ({ report, onExport }) => {
  const [showInsiders, setShowInsiders] = useState(false);

  useEffect(() => {
    console.log("TokenReport received data:", report);
  }, [report]);

  const toggleInsiders = () => {
    setShowInsiders(!showInsiders);
  };

  const formatNumber = (num, decimals = 0) => {
    if (!num) return 'N/A';
    try {
      const bigNum = typeof num === 'string' && num.startsWith('0x') 
        ? ethers.toBigInt(num)
        : ethers.toBigInt(num.toString());
      
      const formatted = ethers.formatUnits(bigNum, decimals);
      return parseFloat(formatted).toLocaleString(undefined, {
        maximumFractionDigits: decimals
      });
    } catch (error) {
      console.error('Error formatting number:', error);
      return num.toString();
    }
  };

  if (!report) {
    return <div className={styles.error}>Error: No token data received.</div>;
  }

  if (report.error) {
    return <div className={styles.error}>Error: {report.error}</div>;
  }

  if (!report.metadata) {
    return <div className={styles.error}>Error: Invalid token data received.</div>;
  }

  return (
    <div className={styles.tokenReport}>
      <h2>Token Report: {report.metadata.name} ({report.metadata.symbol})</h2>
      
      <div className={styles.section}>
        <h3>Token Information</h3>
        <p><strong>Address:</strong> {report.address}</p>
        <p><strong>Name:</strong> {report.metadata.name}</p>
        <p><strong>Symbol:</strong> {report.metadata.symbol}</p>
        <p><strong>Decimals:</strong> {report.metadata.decimals}</p>
        <p><strong>Total Supply:</strong> {formatNumber(report.metadata.totalSupply, report.metadata.decimals)} {report.metadata.symbol}</p>
      </div>

      <div className={styles.section}>
        <h3>Holder Information</h3>
        <p><strong>Total Holders:</strong> {report.holders.toLocaleString()}</p>
        <p><strong>Insider Holders ({'>'}0.50%):</strong> {report.insiders.toLocaleString()}</p>
        <button onClick={toggleInsiders} className={styles.toggleButton}>
          {showInsiders ? 'Hide Insider Details' : 'Show Insider Details'}
        </button>
        {showInsiders && report.insiderDetails && report.insiderDetails.length > 0 ? (
          <div className={styles.insiderList}>
            <h4>Insider Addresses</h4>
            <ul>
              {report.insiderDetails.map((insider, index) => (
                <li key={index}>
                  <span>{insider.address}</span>
                  <span>{insider.formattedBalance} {report.metadata.symbol}</span>
                  <span>({insider.percentage}%)</span>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          showInsiders && <p>No insider holdings found.</p>
        )}
      </div>

      <button onClick={onExport} className={styles.exportButton}>Export Report</button>
    </div>
  );
};

export default TokenReport;