import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
    padding: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
});

const PDFReport = ({ report, reportType }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>{reportType === 'identity' ? 'Identity Report' : 'Token Report'}</Text>
        
        {reportType === 'identity' ? (
          <>
            <Text style={styles.subtitle}>Address Information</Text>
            <Text style={styles.text}>Address: {report?.address || 'N/A'}</Text>
            <Text style={styles.text}>Balance: {report?.balance || 'N/A'} ETH</Text>
            <Text style={styles.text}>Is Contract: {report?.isContract ? 'Yes' : 'No'}</Text>
            <Text style={styles.text}>Last Activity: {report?.lastActivity || 'N/A'}</Text>

            <Text style={styles.subtitle}>Token Balances</Text>
            {report?.tokens?.slice(0, 10).map((token, index) => (
              <Text key={index} style={styles.text}>
                {token?.metadata?.name || 'Unknown'}: {token?.formattedBalance || 'N/A'} {token?.metadata?.symbol || ''}
              </Text>
            ))}
            {report?.tokens?.length > 10 && <Text style={styles.text}>... and {report.tokens.length - 10} more</Text>}

            <Text style={styles.subtitle}>NFTs</Text>
            <Text style={styles.text}>Total NFTs: {report?.nfts?.length || 0}</Text>

            <Text style={styles.subtitle}>Transaction History</Text>
            <Text style={styles.text}>Recent Transactions: {report?.transactionHistory?.length || 0}</Text>
          </>
        ) : (
          <>
            <Text style={styles.subtitle}>Token Information</Text>
            <Text style={styles.text}>Address: {report?.address || 'N/A'}</Text>
            <Text style={styles.text}>Name: {report?.metadata?.name || 'N/A'}</Text>
            <Text style={styles.text}>Symbol: {report?.metadata?.symbol || 'N/A'}</Text>
            <Text style={styles.text}>Decimals: {report?.metadata?.decimals || 'N/A'}</Text>
            <Text style={styles.text}>Total Supply: {report?.metadata?.totalSupply || 'N/A'} {report?.metadata?.symbol || ''}</Text>
            
            <Text style={styles.subtitle}>Holder Information</Text>
            <Text style={styles.text}>Total Holders: {report?.holders || 'N/A'}</Text>
            <Text style={styles.text}>Insider Holders ({'>'}0.50%): {report?.insiders || 'N/A'}</Text>
          </>
        )}
      </View>
    </Page>
  </Document>
);

export default PDFReport;