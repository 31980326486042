import React, { useState, useCallback, useMemo, useEffect } from 'react';
import jazzicon from 'jazzicon';
import makeBlockie from 'ethereum-blockies-base64';
import styles from './Report.module.css';

const ITEMS_PER_PAGE = 20;
const INSIDER_THRESHOLD = 0.5; // 0.5% of total supply

function Report({
  report,
  webacyExposureRisk,
  currentPageERC20,
  totalPagesERC20,
  onPageChangeERC20,
  currentPageFanTokens,
  totalPagesFanTokens,
  onPageChangeFanTokens,
  currentPageNFTs,
  totalPagesNFTs,
  onPageChangeNFTs,
  filterSpam,
  toggleFilterSpam,
  hideSmallBalances,
  toggleHideSmallBalances,
  sortOrder,
  toggleSortOrder,
  onExport
}) {
  const [collapsedSections, setCollapsedSections] = useState({});
  const [expandedColumns, setExpandedColumns] = useState(false);

  useEffect(() => {
    console.log("Report component received data:", { report, webacyExposureRisk });
    console.log("Tokens:", report?.tokens);
    console.log("NFTs:", report?.nfts);
  }, [report, webacyExposureRisk]);

  const toggleSection = useCallback((section) => {
    setCollapsedSections(prev => ({...prev, [section]: !prev[section]}));
  }, []);

  const toggleExpandColumns = useCallback(() => {
    setExpandedColumns(prev => !prev);
  }, []);

  const truncateString = useCallback((str, maxLength = 12) => {
    if (str && str.length > maxLength) {
      return str.substr(0, maxLength - 3) + '...';
    }
    return str;
  }, []);

  const isFanToken = useCallback((token) => {
    const symbol = token?.metadata?.symbol?.toLowerCase() || '';
    return symbol.includes('cid:') || symbol.includes('fid:') || symbol.includes('network:') || symbol.includes('id:');
  }, []);

  const filterAndSortTokens = useCallback((tokens) => {
    if (!Array.isArray(tokens)) return [];
    return tokens
      .filter(token => {
        if (filterSpam) {
          const symbol = token?.metadata?.symbol?.toLowerCase() || '';
          if (symbol.includes('http://') || symbol.includes('https://') || 
              symbol.includes('www.') || symbol.includes('.com') || 
              symbol.includes('.xyz') || symbol.includes('visit')) {
            return false;
          }
        }
        if (hideSmallBalances && parseFloat(token?.formattedBalance || '0') <= 1.00) {
          return false;
        }
        return true;
      })
      .sort((a, b) => {
        const balanceA = parseFloat(a?.formattedBalance || '0');
        const balanceB = parseFloat(b?.formattedBalance || '0');
        return sortOrder === 'asc' ? balanceA - balanceB : balanceB - balanceA;
      });
  }, [filterSpam, hideSmallBalances, sortOrder]);

  const { erc20Tokens, fanTokens } = useMemo(() => {
    console.log("Calculating token lists");
    if (!report?.tokens) {
      console.log("No tokens found in report");
      return { erc20Tokens: [], fanTokens: [] };
    }
    const erc20 = report.tokens.filter(token => !isFanToken(token));
    const fan = report.tokens.filter(token => isFanToken(token));
    
    console.log("ERC20 Tokens:", erc20);
    console.log("Fan Tokens:", fan);
    return { 
      erc20Tokens: filterAndSortTokens(erc20), 
      fanTokens: filterAndSortTokens(fan) 
    };
  }, [report, isFanToken, filterAndSortTokens]);

  const renderTokenList = useCallback((tokens, currentPage, totalPages, onPageChange) => {
    console.log("Rendering token list:", { tokens, currentPage, totalPages });
    if (!Array.isArray(tokens) || tokens.length === 0) {
      return <p>No tokens found.</p>;
    }

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const tokensToDisplay = tokens.slice(startIndex, endIndex);

    return (
      <>
        <div className={styles.tokenColumns}>
          {tokensToDisplay.map((token, index) => (
            <div key={index} className={styles.tokenItem}>
              <span className={styles.tokenName} title={token?.metadata?.name}>{token?.metadata?.name}</span>
              <span className={styles.tokenSymbol}>{token?.metadata?.symbol}</span>
              <span className={styles.tokenBalance} title={token?.formattedBalance}>{token?.formattedBalance}</span>
              <a 
                href={`https://basescan.org/token/${token?.contractAddress}`} 
                target="_blank" 
                rel="noopener noreferrer"
                className={styles.tokenLink}
              >
                →
              </a>
            </div>
          ))}
        </div>
        {totalPages > 1 && (
          <div className={styles.paginationControls}>
            <button
              className={styles.paginationButton}
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>{currentPage} of {totalPages}</span>
            <button
              className={styles.paginationButton}
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        )}
      </>
    );
  }, []);

  const renderNFTList = useCallback((nfts, currentPage, totalPages, onPageChange) => {
    console.log("Rendering NFT list:", { nfts, currentPage, totalPages });
    if (!Array.isArray(nfts) || nfts.length === 0) {
      return <p>No NFTs found.</p>;
    }

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const nftsToDisplay = nfts.slice(startIndex, endIndex);

    return (
      <>
        <div className={styles.nftColumns}>
          {nftsToDisplay.map((nft, index) => (
            <div key={index} className={styles.nftItem}>
              <img 
                src={nft.media[0]?.gateway || '/placeholder-nft.png'} 
                alt={nft.title || 'NFT'} 
                className={styles.nftImage} 
              />
              <span className={styles.nftName} title={nft.title || 'Unnamed NFT'}>
                {truncateString(nft.title || 'Unnamed NFT')}
              </span>
              <span className={styles.nftId} title={`ID: ${nft.tokenId}`}>
                ID: {truncateString(nft.tokenId)}
              </span>
              <a 
                href={`https://basescan.org/token/${nft.contract.address}`} 
                target="_blank" 
                rel="noopener noreferrer"
                className={styles.nftLink}
              >
                View on BaseScan
              </a>
            </div>
          ))}
        </div>
        {totalPages > 1 && (
          <div className={styles.paginationControls}>
            <button
              className={styles.paginationButton}
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>{currentPage} of {totalPages}</span>
            <button
              className={styles.paginationButton}
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        )}
      </>
    );
  }, [truncateString]);

  const renderWebacyExposureRisk = useCallback(() => {
    if (!webacyExposureRisk) {
      return <p>No Webacy Exposure Risk data available.</p>;
    }

    return (
      <div className={styles.webacyExposureRisk}>
        <h4>Webacy Safety Score: {webacyExposureRisk.safetyScore}</h4>
        <p>Risk Level: {webacyExposureRisk.riskLevel}</p>
        <ul>
          {webacyExposureRisk.exposures.map((exposure, index) => (
            <li key={index}>
              {exposure.type}: {exposure.severity} ({exposure.description})
            </li>
          ))}
        </ul>
      </div>
    );
  }, [webacyExposureRisk]);

  const renderJazzicon = useCallback(() => {
    if (!report.address) return null;

    const seed = parseInt(report.address.slice(2, 10), 16);
    const jazziconElement = jazzicon(64, seed); // Increased size to 64px
    const blockiesSrc = makeBlockie(report.address);

    return (
      <div className={styles.addressIcons}>
        <div className={styles.iconWrapper} dangerouslySetInnerHTML={{ __html: jazziconElement.outerHTML }} />
        <img src={blockiesSrc} alt="Blockies" className={styles.blockies} />
      </div>
    );
  }, [report.address]);

  const renderTransactionHistory = useCallback(() => {
    if (!Array.isArray(report?.transactionHistory) || report.transactionHistory.length === 0) {
      return <p>No transaction history available.</p>;
    }

    return (
      <div className={styles.transactionHistory}>
        <h4>Recent Transactions</h4>
        <div className={styles.tableWrapper}>
          <table className={`${styles.transactionTable} ${expandedColumns ? styles.expanded : ''}`}>
            <thead>
              <tr>
                <th>Type</th>
                <th>From</th>
                <th>To</th>
                <th>Value</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {report.transactionHistory.slice(0, 10).map((tx, index) => (
                <tr key={index}>
                  <td>{tx?.category}</td>
                  <td title={tx?.from}>{tx?.from}</td>
                  <td title={tx?.to}>{tx?.to}</td>
                  <td>{tx?.value} {tx?.asset}</td>
                  <td>{tx?.metadata?.blockTimestamp ? new Date(tx.metadata.blockTimestamp).toLocaleString() : 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button onClick={toggleExpandColumns} className={styles.expandButton}>
          {expandedColumns ? 'Collapse Columns' : 'Expand Columns'}
        </button>
      </div>
    );
  }, [report, expandedColumns, toggleExpandColumns]);

  return (
    <div className={styles.report}>
      <h2>Identity Report</h2>
      
      <div className={styles.section}>
        <div className={styles.sectionHeader} onClick={() => toggleSection('addressInfo')}>
          <h3>Address Information</h3>
          <span className={styles.collapseIcon}>{collapsedSections.addressInfo ? '►' : '▼'}</span>
        </div>
        {!collapsedSections.addressInfo && (
          <div className={styles.sectionContent}>
            <div className={styles.addressInfoContent}>
              <div className={styles.addressDetails}>
                <p><strong>Address:</strong> {report.address}</p>
                <p><strong>Balance:</strong> {report.balance} ETH</p>
                <p><strong>Is Contract:</strong> {report.isContract ? 'Yes' : 'No'}</p>
                <p><strong>Last Activity:</strong> {report.lastActivity ? new Date(report.lastActivity).toLocaleString() : 'N/A'}</p>
              </div>
              {renderJazzicon()}
            </div>
          </div>
        )}
      </div>
      <div className={styles.section}>
        <div className={styles.sectionHeader} onClick={() => toggleSection('safetyExposure')}>
          <h3>Safety Exposure Risk</h3>
          <span className={styles.collapseIcon}>{collapsedSections.safetyExposure ? '►' : '▼'}</span>
        </div>
        {!collapsedSections.safetyExposure && (
          <div className={styles.sectionContent}>
            {renderWebacyExposureRisk()}
          </div>
        )}
      </div>
      <div className={styles.section}>
        <div className={styles.sectionHeader} onClick={() => toggleSection('tokenBalances')}>
          <h3>Token Balances</h3>
          <span className={styles.collapseIcon}>{collapsedSections.tokenBalances ? '►' : '▼'}</span>
        </div>
        {!collapsedSections.tokenBalances && (
          <div className={styles.sectionContent}>
            <div className={styles.filterSortControls}>
              <label>
                <input
                  type="checkbox"
                  checked={filterSpam}
                  onChange={toggleFilterSpam}
                />
                Filter Spam Tokens
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={hideSmallBalances}
                  onChange={toggleHideSmallBalances}
                />
                Hide Small Balances
              </label>
              <button onClick={toggleSortOrder} className={styles.sortButton}>
                Sort by Balance ({sortOrder === 'asc' ? 'Ascending' : 'Descending'})
              </button>
            </div>
            <h4>ERC20 Tokens</h4>
            {renderTokenList(erc20Tokens, currentPageERC20, totalPagesERC20, onPageChangeERC20)}
            <h4>Fan Tokens</h4>
            {renderTokenList(fanTokens, currentPageFanTokens, totalPagesFanTokens, onPageChangeFanTokens)}
          </div>
        )}
      </div>

      {report.nfts && report.nfts.length > 0 && (
        <div className={styles.section}>
          <div className={styles.sectionHeader} onClick={() => toggleSection('nfts')}>
            <h3>NFTs</h3>
            <span className={styles.collapseIcon}>{collapsedSections.nfts ? '►' : '▼'}</span>
          </div>
          {!collapsedSections.nfts && (
            <div className={styles.sectionContent}>
              {renderNFTList(report.nfts, currentPageNFTs, totalPagesNFTs, onPageChangeNFTs)}
            </div>
          )}
        </div>
      )}

      <div className={styles.section}>
        <div className={styles.sectionHeader} onClick={() => toggleSection('transactionHistory')}>
          <h3>Transaction History</h3>
          <span className={styles.collapseIcon}>{collapsedSections.transactionHistory ? '►' : '▼'}</span>
        </div>
        {!collapsedSections.transactionHistory && (
          <div className={styles.sectionContent}>
            {renderTransactionHistory()}
          </div>
        )}
      </div>

      <button onClick={onExport} className={styles.exportButton}>Export Report</button>
    </div>
  );
}

export default React.memo(Report);