import React from 'react';
import styles from './SearchBar.module.css';

/**
 * SearchBar component for user input
 * @param {Object} props - Component props
 * @param {string} props.searchInput - Current search input value
 * @param {Function} props.setSearchInput - Function to update search input
 * @param {Function} props.onSearch - Function to handle search submission
 * @param {boolean} props.loading - Loading state
 * @param {string} props.placeholder - Placeholder text for input
 * @returns {React.ReactElement} Rendered SearchBar component
 */
function SearchBar({ searchInput, setSearchInput, onSearch, loading, placeholder }) {
  return (
    <div className={styles.searchBarContainer}>
      <form onSubmit={onSearch} className={styles.searchForm}>
        <input
          type="text"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder={placeholder}
          className={styles.searchInput}
          disabled={loading}
        />
        <button type="submit" className={styles.searchButton} disabled={loading}>
          {loading ? 'Searching...' : 'Search'}
        </button>
      </form>
    </div>
  );
}

export default SearchBar;