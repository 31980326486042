import { ethers } from 'ethers';
import { Alchemy, Network } from 'alchemy-sdk';
import axios from 'axios';

// Utility function for safe number operations
const safeNumberOp = (a, b, operation) => {
  try {
    const numA = parseFloat(a);
    const numB = parseFloat(b);
    switch (operation) {
      case 'mul': return numA * numB;
      case 'div': return numA / numB;
      case 'add': return numA + numB;
      case 'sub': return numA - numB;
      case 'gte': return numA >= numB;
      default: throw new Error('Unsupported operation');
    }
  } catch (error) {
    console.error('Error in number operation:', error);
    return 0;
  }
};

// Configuration constants
const ITEMS_PER_PAGE = 100;
const INSIDER_THRESHOLD = 0.5; // 0.5% of total supply

// Environment variables
const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY;
const BASESCAN_API_KEY = process.env.REACT_APP_BASESCAN_API_KEY;
const WEBACY_API_KEY = process.env.REACT_APP_WEBACY_API_KEY;
const INFURA_PROJECT_ID = process.env.REACT_APP_INFURA_PROJECT_ID;
const BASE_RPC_URL = process.env.REACT_APP_BASE_RPC_URL;

// API endpoints
const BASESCAN_API_ENDPOINT = 'https://api.basescan.org/api';
const WEBACY_API_URL = 'https://api.webacy.com/quick-profile';

// Initialize Alchemy SDK
const alchemyConfig = {
  apiKey: ALCHEMY_API_KEY,
  network: Network.BASE_MAINNET,
};
const alchemy = new Alchemy(alchemyConfig);

// Configure Infura provider for ENS resolution on Ethereum mainnet
const infuraProvider = new ethers.JsonRpcProvider(`https://mainnet.infura.io/v3/${INFURA_PROJECT_ID}`);

/**
 * Safely converts a value to a string, handling BigNumber objects
 * @param {*} value - The value to convert
 * @returns {string} The value as a string
 */
const safeToString = (value) => {
  if (typeof value === 'object' && value.type === 'ethers.toBigInt()') {
    return value.hex;
  }
  return String(value);
};

/**
 * Resolves an ENS name to its corresponding Ethereum address
 * @param {string} ensName - ENS name to resolve
 * @returns {Promise<string|null>} Resolved Ethereum address or null if not found
 */
export const resolveEnsName = async (ensName) => {
  try {
    console.log(`Resolving ENS name: ${ensName}`);
    const address = await infuraProvider.resolveName(ensName);
    console.log(`Resolved address: ${address}`);
    return address;
  } catch (error) {
    console.error('Error resolving ENS name:', error);
    return null;
  }
};

/**
 * Fetches the balance of an address using Basescan API
 * @param {string} address - The address to fetch the balance for
 * @returns {Promise<string>} The balance in ether
 */
const fetchBalanceFromBasescan = async (address) => {
  try {
    const response = await axios.get(BASESCAN_API_ENDPOINT, {
      params: {
        module: 'account',
        action: 'balance',
        address: address,
        tag: 'latest',
        apikey: BASESCAN_API_KEY
      }
    });

    if (response.data.status === '1') {
      return ethers.formatEther(safeToString(response.data.result));
    } else {
      throw new Error('Failed to fetch balance from Basescan');
    }
  } catch (error) {
    console.error('Error fetching balance from Basescan:', error);
    return '0';
  }
};

/**
 * Fetches basic information for a given address or ENS name
 * @param {string} addressOrEns - The address or ENS name to fetch information for
 * @returns {Promise<Object>} Basic identity information
 */
export const fetchBasicInfo = async (addressOrEns) => {
  try {
    console.log(`Fetching basic info for address or ENS: ${addressOrEns}`);
    let resolvedAddress = addressOrEns;
    if (addressOrEns.toLowerCase().endsWith('.eth')) {
      resolvedAddress = await resolveEnsName(addressOrEns);
      if (!resolvedAddress) {
        throw new Error('Unable to resolve ENS name');
      }
    }
    const [balance, isContract] = await Promise.all([
      fetchBalanceFromBasescan(resolvedAddress),
      alchemy.core.isContractAddress(resolvedAddress)
    ]);
    
    return {
      address: resolvedAddress,
      balance,
      isContract
    };
  } catch (error) {
    console.error('Error fetching basic info:', error);
    return {
      address: addressOrEns,
      balance: '0',
      isContract: false,
      error: error.message
    };
  }
};

/**
 * Fetches token balances for a given address
 * @param {string} address - The address to fetch token balances for
 * @returns {Promise<Array>} Array of token balance objects
 */
export const fetchTokenBalances = async (address) => {
  try {
    console.log(`Fetching token balances for address: ${address}`);
    const balances = await alchemy.core.getTokenBalances(address);
    
    console.log(`Found ${balances.tokenBalances.length} tokens for address ${address}`);

    const tokenMetadata = await Promise.all(
      balances.tokenBalances.map(async (token) => {
        try {
          const metadata = await alchemy.core.getTokenMetadata(token.contractAddress);
          console.log(`Fetched metadata for token ${token.contractAddress}:`, metadata);
          return metadata;
        } catch (error) {
          console.warn(`Failed to fetch metadata for token ${token.contractAddress}:`, error);
          return null;
        }
      })
    );

    const formattedBalances = balances.tokenBalances.map((token, index) => {
      const metadata = tokenMetadata[index];
      if (!metadata) {
        console.log(`Skipping token ${token.contractAddress} due to missing metadata`);
        return null;
      }
      try {
        const tokenBalanceString = safeToString(token.tokenBalance);
        const formattedBalance = ethers.formatUnits(tokenBalanceString, metadata.decimals);
        
        console.log(`Formatted balance for token ${token.contractAddress}: ${formattedBalance}`);
        return {
          contractAddress: token.contractAddress,
          tokenBalance: tokenBalanceString,
          metadata,
          formattedBalance
        };
      } catch (error) {
        console.warn(`Error formatting balance for token ${token.contractAddress}:`, error);
        return null;
      }
    }).filter(token => token !== null);

    console.log(`Successfully processed ${formattedBalances.length} tokens`);
    return formattedBalances;
  } catch (error) {
    console.error('Error fetching token balances:', error);
    return [];
  }
};

/**
 * Fetches NFTs for a given address
 * @param {string} address - Ethereum address
 * @returns {Promise<Array>} Array of NFT objects
 */
export const fetchNFTs = async (address) => {
  try {
    console.log(`Fetching NFTs for address: ${address}`);
    const nfts = await alchemy.nft.getNftsForOwner(address);
    return nfts.ownedNfts;
  } catch (error) {
    console.error('Error fetching NFTs:', error);
    return [];
  }
};

/**
 * Fetches transaction history for a given address
 * @param {string} address - Ethereum address
 * @returns {Promise<Array>} Array of transaction objects
 */
export const fetchTransactionHistory = async (address) => {
  try {
    console.log(`Fetching transaction history for address: ${address}`);
    const history = await alchemy.core.getAssetTransfers({
      fromAddress: address,
      category: ["external", "erc20", "erc721", "erc1155"],
      order: "desc",
      withMetadata: true,
      maxCount: ITEMS_PER_PAGE
    });
    return history.transfers;
  } catch (error) {
    console.error('Error fetching transaction history:', error);
    return [];
  }
};

/**
 * Calculates the wallet age based on the first transaction
 * @param {Array} transactions - Array of transactions
 * @returns {number} Wallet age in days
 */
const calculateWalletAge = (transactions) => {
  if (transactions.length === 0) return 0;
  const oldestTx = transactions[transactions.length - 1];
  const oldestTxDate = new Date(oldestTx.metadata.blockTimestamp);
  const now = new Date();
  return Math.ceil((now - oldestTxDate) / (1000 * 60 * 60 * 24));
};

/**
 * Calculates the number of potential insider holdings
 * @param {Array} tokenBalances - Array of token balance objects
 * @returns {number} Number of potential insider holdings
 */
const calculatePotentialInsiderHoldings = (tokenBalances) => {
  return tokenBalances.filter(token => {
    const totalSupply = parseFloat(token.metadata.totalSupply);
    if (!totalSupply) return false;
    const percentage = (parseFloat(token.formattedBalance) / totalSupply) * 100;
    return percentage >= INSIDER_THRESHOLD;
  }).length;
};

/**
 * Fetches Webacy Exposure Risk information for a given address
 * @param {string} address - Ethereum address
 * @returns {Promise<Object>} Webacy Exposure Risk information
 */
export const fetchWebacyExposureRisk = async (address) => {
  try {
    console.log(`Fetching Webacy Exposure Risk for address: ${address}`);
    const response = await axios.get(`${WEBACY_API_URL}/${address}?chain=base&withApprovals=true`, {
      headers: {
        'accept': 'application/json',
        'x-api-key': WEBACY_API_KEY
      }
    });
    if (!response.data) {
      throw new Error('No data received from Webacy API');
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching Webacy Exposure Risk:', error);
    return null;
  }
};

/**
 * Fetches complete identity report for a given address or ENS name
 * @param {string} addressOrEns - The address or ENS name to fetch the report for
 * @returns {Promise<Object>} Complete identity report data
 */
export const fetchCompleteIdentityReport = async (addressOrEns) => {
  try {
    console.log(`Fetching complete identity report for address or ENS: ${addressOrEns}`);
    let resolvedAddress = addressOrEns;
    if (addressOrEns.toLowerCase().endsWith('.eth')) {
      resolvedAddress = await resolveEnsName(addressOrEns);
      if (!resolvedAddress) {
        throw new Error('Unable to resolve ENS name');
      }
    }
    const [basicInfo, tokenBalances, nfts, transactionHistory, webacyRisk] = await Promise.all([
      fetchBasicInfo(resolvedAddress),
      fetchTokenBalances(resolvedAddress),
      fetchNFTs(resolvedAddress),
      fetchTransactionHistory(resolvedAddress),
      fetchWebacyExposureRisk(resolvedAddress)
    ]);

    const walletAge = calculateWalletAge(transactionHistory);
    const lastActivity = transactionHistory.length > 0 ? transactionHistory[0].metadata.blockTimestamp : null;

    return {
      ...basicInfo,
      tokens: tokenBalances,
      nfts,
      transactionHistory,
      walletAge,
      lastActivity,
      potentialInsiderHoldings: calculatePotentialInsiderHoldings(tokenBalances),
      webacyExposureRisk: webacyRisk
    };
  } catch (error) {
    console.error('Error fetching complete identity report:', error);
    return {
      address: addressOrEns,
      error: error.message
    };
  }
};

/**
 * Fetches token metadata for a given token address
 * @param {string} tokenAddress - Token contract address
 * @returns {Promise<Object>} Token metadata
 */
export const fetchTokenMetadata = async (tokenAddress) => {
  try {
    console.log(`Fetching token metadata for address: ${tokenAddress}`);
    const metadata = await alchemy.core.getTokenMetadata(tokenAddress);
    return metadata;
  } catch (error) {
    console.error('Error fetching token metadata:', error);
    return null;
  }
};

/**
 * Fetches token holders for a given token address
 * @param {string} tokenAddress - Token contract address
 * @returns {Promise<Array>} Array of token holders
 */
export const fetchTokenHolders = async (tokenAddress) => {
  try {
    console.log(`Fetching token holders for address: ${tokenAddress}`);
    const holders = await alchemy.core.getTokenBalances(tokenAddress);
    return holders.tokenBalances;
  } catch (error) {
    console.error('Error fetching token holders:', error);
    return [];
  }
};

/**
 * Fetches token data from Basescan
 * @param {string} tokenAddress - Token contract address
 * @returns {Promise<Object|null>} Token data including total supply, or null if unavailable
 */
export const fetchTokenDataFromBasescan = async (tokenAddress) => {
  try {
    console.log(`Fetching token data from Basescan for address: ${tokenAddress}`);
    const response = await axios.get(BASESCAN_API_ENDPOINT, {
      params: {
        module: 'stats',
        action: 'tokensupply',
        contractaddress: tokenAddress,
        apikey: BASESCAN_API_KEY
      }
    });

    if (response.data.status === '1') {
      return {
        totalSupply: response.data.result
      };
    } else {
      console.warn('Basescan API returned an error:', response.data.message);
      return null;
    }
  } catch (error) {
    console.error('Error fetching token data from Basescan:', error);
    return null;
  }
};

// Minimal ABI for ERC20 totalSupply function
const minABI = [
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [{ name: "", type: "uint256" }],
    type: "function",
  },
];

/**
 * Fetches total supply directly from the token contract
 * @param {string} tokenAddress - Token contract address
 * @returns {Promise<string>} Total supply as a string
 */
const fetchTotalSupplyFromContract = async (tokenAddress) => {
  try {
    const provider = new ethers.JsonRpcProvider(BASE_RPC_URL);
    const contract = new ethers.Contract(tokenAddress, minABI, provider);
    const totalSupply = await contract.totalSupply();
    return totalSupply.toString();
  } catch (error) {
    console.error('Error fetching total supply from contract:', error);
    return '0';
  }
};

/**
 * Fetches complete token report
 * @param {string} tokenAddress - The token contract address
 * @returns {Promise<Object>} Complete token report data
 */
export const fetchTokenReport = async (tokenAddress) => {
  try {
    console.log(`Fetching token report for address: ${tokenAddress}`);
    const [alchemyData, basescanData, holders, contractTotalSupply] = await Promise.all([
      fetchTokenMetadata(tokenAddress),
      fetchTokenDataFromBasescan(tokenAddress),
      fetchTokenHolders(tokenAddress),
      fetchTotalSupplyFromContract(tokenAddress)
    ]);

    if (!alchemyData) {
      throw new Error('Failed to fetch token metadata from Alchemy');
    }

    console.log('Alchemy data:', alchemyData);
    console.log('Basescan data:', basescanData);
    console.log('Contract total supply:', contractTotalSupply);

    const totalSupply = contractTotalSupply || basescanData?.totalSupply || alchemyData.totalSupply;

    console.log('Total supply:', totalSupply);

    const insiders = calculateInsiders(holders, totalSupply, alchemyData.decimals);

    const reportData = {
      address: tokenAddress,
      metadata: {
        ...alchemyData,
        totalSupply: totalSupply
      },
      holders: holders.length,
      insiders: insiders.length,
      insiderDetails: insiders
    };

    console.log('Token report data:', reportData);

    return reportData;
  } catch (error) {
    console.error('Error fetching token report:', error);
    return {
      address: tokenAddress,
      error: error.message
    };
  }
};

/**
 * Calculates insider holders (holders with more than 0.50% of total supply)
 * @param {Array} holders - Array of token holders
 * @param {string} totalSupply - Total token supply
 * @param {number} decimals - Token decimals
 * @returns {Array} Array of insider holders
 */
const calculateInsiders = (holders, totalSupply, decimals) => {
  if (!totalSupply) return [];
  
  const parseBigInt = (value) => {
    try {
      return ethers.getBigInt(value);
    } catch (error) {
      console.error('Error parsing BigInt:', error);
      return ethers.getBigInt(0);
    }
  };

  const totalSupplyBN = parseBigInt(totalSupply);
  const insiderThresholdBN = totalSupplyBN * ethers.getBigInt(5) / ethers.getBigInt(1000); // 0.5%
  
  return holders.filter(holder => {
    if (!holder.tokenBalance) return false;
    const balanceBN = parseBigInt(holder.tokenBalance);
    return balanceBN >= insiderThresholdBN;
  }).map(insider => {
    const balanceBN = parseBigInt(insider.tokenBalance);
    const percentage = Number((balanceBN * ethers.getBigInt(10000) / totalSupplyBN)) / 100;
    return {
      address: insider.address,
      balance: insider.tokenBalance,
      formattedBalance: ethers.formatUnits(balanceBN, decimals),
      percentage: percentage.toFixed(2)
    };
  });
};

/**
 * Utility function to format balance for display
 * @param {string} balance - The balance to format
 * @param {number} decimals - The number of decimal places
 * @returns {string} Formatted balance
 */
export const formatBalance = (balance, decimals = 18) => {
  try {
    return ethers.formatUnits(safeToString(balance), decimals);
  } catch (error) {
    console.warn('Error formatting balance:', error);
    return '0';
  }
};