import React from 'react';
import styles from './Header.module.css';

function Header({ theme, toggleTheme }) {
  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <img src="/assets/header.png" alt="NexID Header" className={styles.headerImage} />
        <button onClick={toggleTheme} className={styles.themeToggle}>
          {theme === 'light' ? '🌙' : '☀️'}
        </button>
      </div>
    </header>
  );
}

export default Header;